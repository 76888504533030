const CryptoJS = require('crypto-js');  //引用AES源码js
const key = CryptoJS.enc.Utf8.parse("1234123412ABCDEF");  //十六位十六进制数作为密钥
const iv = CryptoJS.enc.Utf8.parse('ABCDEF1234123412');   //十六位十六进制数作为密钥偏移量

/**
 * 创建GUID
 */
export function uuid() {
    const s = [];
    const hexDigits = "0123456789abcdef";
    for (let i = 0; i < 32; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }
    s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
    s[19] = hexDigits.substr((s[19] as any & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

    const uuid = s.join("");
    return uuid;
}
//加密方法
export function Encrypt(word: any) {
    const srcs = CryptoJS.enc.Utf8.parse(word);
    const encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return encrypted.ciphertext.toString().toUpperCase();
}

//解密方法
export function Decrypt(word: any) {
    const encryptedHexStr = CryptoJS.enc.Hex.parse(word);
    const srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
    const decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    const decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);
    return decryptedStr.toString();
}

/**
 * 格式化net传过来的日期
 * @param {*} Dtime     C#的时间日期值
 * @param {*} format    格式化
 */
export function FormatDate(Dtime: any, format: any) {
    if (Dtime) {
        let date = Dtime;
        if (/(y+)/.test("yyyy-MM-dd hh:mm:ss")) {
            try {
                format = format.replace(
                    RegExp.$1,
                    (date.getFullYear() + "").substr(4 - RegExp.$1.length)
                );
            } catch (e) {
                date = new Date(parseInt(Dtime.slice(6, 19)));
                if (!date || date.toUTCString() === "Invalid Date") {
                    return "";
                }
            }
        } else {
            date = new Date(parseInt(Dtime.slice(6, 19)));
            if (!date || date.toUTCString() === "Invalid Date") {
                return "";
            }
        }
        const map = {
            M: date.getMonth() + 1, // 月份
            d: date.getDate(), // 日
            h: date.getHours(), // 小时
            m: date.getMinutes(), // 分
            s: date.getSeconds(), // 秒
            q: Math.floor((date.getMonth() + 3) / 3), // 季度
            S: date.getMilliseconds() // 毫秒
        } as any;
        format = format.replace(/([yMdhmsqS])+/g, (all: any, t: any) => {
            let v = map[t];
            if (v !== undefined) {
                if (all.length > 1) {
                    v = "0" + v;
                    v = v.substr(v.length - 2);
                }
                return v;
            } else if (t === "y") {
                return (date.getFullYear() + "").substr(4 - all.length);
            }
            return all;
        });
        return format;
    }
}

/**
 * 获取cookie
 * @param {*} objName     要获取的key值
 */
export function getCookie(objName: string): string | undefined{
    console.log("document.cookie", document.cookie);
    const arrStr = document.cookie.split("; ");
    for (let i = 0; i < arrStr.length; i++) {
			const temp = arrStr[i].split("=");
			if (temp[0] == objName){
                return temp[1];
			}
		}
		
}

export function clone(json:any):any {
 return JSON.parse(JSON.stringify(json));
}
/**
 * 校验密码强度
 * @param {string} value 密码
 */
export function CheckPassWordLevel(value: any) {
  // 1： 表示第一个级别 2：表示第二个级别 3：表示第三个级别
  // 4： 表示第四个级别 5：表示第五个级别
  let modes = 1;
  if (value.length < 6) {
    // 最初级别
    return modes;
  }
  if (/\d/.test(value)) {
    // 如果用户输入的密码 包含了数字
    modes++;
  }
  if (/[a-z]/.test(value)) {
    // 如果用户输入的密码 包含了小写的a到z
    modes++;
  }
  if (/[A-Z]/.test(value)) {
    // 如果用户输入的密码 包含了大写的A到Z
    modes++;
  }
  if (/\W/.test(value)) {
    // 如果是非数字 字母 下划线
    modes++;
  }

  return modes;
}