import { get } from '@/scripts/http';
import { Decrypt } from "@/scripts/utils";


const ORGTOKEN = localStorage.getItem("OrgToken") ? Decrypt(localStorage.getItem("OrgToken")) : '';

export default {
    ServiceURL: `/${ORGTOKEN}/V4/Ctrl`,
    //获取系统配置文件信息
    async GetConfigInfo(ORGTOKEN: string) {
        const res = await get(`/${ORGTOKEN}/V4/Ctrl/GetConfigInfo`, {});
        return res;
    }
}