import { h, resolveComponent } from "vue";
import { createRouter, createWebHistory, RouteRecordRaw, createWebHashHistory } from 'vue-router'
import wx from "weixin-js-sdk";

const emptyLayout = { render: () => h(resolveComponent("router-view")) };

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue")
    // component: () => import(/* webpackChunkName: "home" */ "../views/homepage/index.vue")
  },
  {
    path: '/homepage',
    name: 'HomePage',
    component: () => import(/* webpackChunkName: "home" */ "../views/homepage/index.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/login.vue")
  },
  {
    path: "/wxlogin",
    name: "wxLogin",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/wxLogin.vue")
  },
  {
    path: "/rtmlogin",
    name: "rtmLogin",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/rtmLogin.vue")
  },
  {
    path: "/loginh5",
    name: "Loginh5",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/loginH5.vue")
  },
  //闽江统一登录回调页
  {
    path: "/mjlogin",
    name: "mjLogin",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/mjLogin.vue")
  },
  // 统一登录回调页
  {
    path: "/unifylogin",
    name: "unifyLogin",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/unifylogin.vue")
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/logout.vue")
  },
  {
    path: "/auth/ding",
    name: "ddLogin",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/ddLogin.vue")
  },
  {
    path: "/ddMessage",
    name: "ddMessage",
    component: () => import(/* webpackChunkName: "login" */ "../views/login/ddMessage.vue")
  },
  {
    path: "/unlock",
    name: "Unlock",
    component: () => import(/* webpackChunkName: "login" */ "../views/unlock/index.vue")
  },
  // 设置、修改下课时间
  {
    path: "/afterclass",
    name: "AfterClass",
    component: () => import(/* webpackChunkName: "login" */ "../views/afterclass/afterclass.vue")
  },
  //个人中心
  {
    path: "/person",
    name: "person",
    redirect: '/person/index',
    component: emptyLayout,
    children: [
      {
        path: 'index',
        name: "PersonIndex",
        component: () => import(/* webpackChunkName: "login" */ "../views/person/index.vue")
      },
      {
        path: 'users',
        name: "users",
        component: () => import(/* webpackChunkName: "login" */ "../views/person/users.vue")
      },
      {
        path: 'pwd',
        name: "PersonPwd",
        component: () => import(/* webpackChunkName: "login" */ "../views/person/editPwd.vue")
      },
    ]
  },
  {
    path: '/record',
    name: 'Record',
    redirect: '/record/list',
    component: emptyLayout,
    children: [
      {
        path: 'list',
        name: "RecordList",
        component: () => import(/* webpackChunkName: "record" */ "../views/record-source/index.vue")
      },
      {
        path: 'details',
        name: "RecordDetails",
        component: () => import(/* webpackChunkName: "record" */ "../views/record-source/detail.vue")
      },
    ]
  },
  // 教室管控
  {
    path: '/classroom',
    name: 'ClassRoom',
    redirect: '/classroom/list',
    component: emptyLayout,
    children: [
      {
        path: 'list',
        name: "ClassRoomList",
        component: () => import(/* webpackChunkName: "classroom" */ "../views/classroom/index.vue")
      },
      {
        path: 'detail',
        name: "ClassRoomDetail",
        component: () => import(/* webpackChunkName: "classroom" */ "../views/classroom/detail.vue")
      },
      {
        path: 'setting',
        name: "ClsRoomSet",
        component: () => import(/* webpackChunkName: "classroom" */ "../views/classroom/setting.vue")
      }
    ]
  },
  // 巡课中心
  {
    path: '/patrol',
    name: 'Patrol',
    redirect: '/patrol/center',
    component: emptyLayout,
    children: [
      {
        path: 'Center',
        name: "Center",
        component: () => import(/* webpackChunkName: "course" */ "../views/course-patrol/center.vue")
      },
      {
        path: 'index',
        name: "CourseList",
        component: () => import(/* webpackChunkName: "course" */ "../views/course-patrol/index.vue")
      },
      {
        path: 'list',
        name: "PatrolList",
        component: () => import(/* webpackChunkName: "course" */ "../views/course-patrol/list.vue")
      },
      {
        path: 'details',
        name: "CourseDetails",
        component: () => import(/* webpackChunkName: "course" */ "../views/course-patrol/details.vue")
      },
      {
        path: 'eval',
        name: "EvalList",
        meta: { title: "督导巡课" },
        component: () => import(/* webpackChunkName: "course" */ "../views/course-patrol/evalList.vue")
      },
    ]
  },
  // 预约
  {
    path: '/subscribe',
    name: "Subscribe",
    component: emptyLayout,
    children: [
      // 教室预约
      {
        path: "classroom",
        name: 'SubscribeClassRoom',
        redirect: '/classroom/list',
        component: emptyLayout,
        children: [
          {
            path: 'list',
            name: "SubscribeClsList",
            component: () => import(/* webpackChunkName: "subscribe" */ "../views/subscribe/classroom/index.vue")
          },
          {
            path: "detail",
            name: "SubscribeClsDetails",
            component: () => import(/* webpackChunkName: "subscribe" */ "../views/subscribe/classroom/details.vue")
          },
          {
            path: "stuready",
            name: "SubscribeStudentReady",
            component: () => import(/* webpackChunkName: "subscribe" */ "../views/subscribe/classroom/stuready.vue")
          },
          {
            path: "create",
            name: "SubscribeCreate",
            component: () => import(/* webpackChunkName: "subscribe" */ "../views/subscribe/classroom/create.vue")
          },
          {
            path: "approve",
            name: "SubscribeApprove",
            component: () => import(/* webpackChunkName: "subscribe" */ "../views/subscribe/classroom/approve.vue")
          }
        ]
      },
      // 预约管理
      {
        path: 'management',
        name: 'SubscribeManagement',
        redirect: '/management/list',
        component: emptyLayout,
        children: [
          {
            path: 'list',
            name: "SubscribeManagementList",
            component: () => import(/* webpackChunkName: "subscribe" */ "../views/subscribe/management/index.vue")
          },
          {
            path: "detail",
            name: "SubscribeManagementDetails",
            component: () => import(/* webpackChunkName: "subscribe" */ "../views/subscribe/management/details.vue")
          }
        ]
      },

    ]
  },


  // {
  //   path: "/schedule",
  //   component: emptyLayout,
  //   name: "schedule",
  //   redirect: "/schedule/my",
  //   children: [
  //     {
  //       path: "my",
  //       name: "myschedule",
  //       component: () => import(/* webpackChunkName: "schedule" */ "../views/schedule/my.vue")
  //     },
  //     {
  //       path: "today",
  //       name: "todayschedule",
  //       component: () => import(/* webpackChunkName: "schedule" */ "../views/schedule/today.vue")
  //     }
  //   ]
  // },
  // {
  //   path: "/account",
  //   name: "Account",
  //   component: () => import(/* webpackChunkName: "account" */ "../views/account/index.vue")
  // },
  {
    path: '/property',
    name: 'Property',
    redirect: '/property/list',
    component: emptyLayout,
    children: [
      {
        path: 'list',
        name: "OrderList",
        component: () => import(/* webpackChunkName: "property" */ "../views/property/order.vue")
      },
      {
        path: 'create',
        name: "Create",
        component: () => import(/* webpackChunkName: "property" */ "../views/property/create.vue")
      },
      {
        path: 'pending',
        name: "Pending",
        component: () => import(/* webpackChunkName: "property" */ "../views/property/pending.vue")
      },
      {
        path: 'overhaul',
        name: "Overhaul",
        component: () => import(/* webpackChunkName: "property" */ "../views/property/overhaul.vue")
      },
      {
        path: 'approve',
        name: "Approve",
        component: () => import(/* webpackChunkName: "property" */ "../views/property/approve.vue")
      },
      {
        path: 'processing',
        name: "Processing",
        component: () => import(/* webpackChunkName: "property" */ "../views/property/processing.vue")
      },
      {
        path: 'acceptance',
        name: "Acceptance",
        component: () => import(/* webpackChunkName: "property" */ "../views/property/acceptance.vue")
      },
      {
        path: 'finished',
        name: "Finished",
        component: () => import(/* webpackChunkName: "property" */ "../views/property/finished.vue")
      },
      {
        path: 'closed',
        name: 'Closed',
        component: () => import(/* webpackChunkName: "property" */ "../views/property/closed.vue")
      }
    ]
  },
  // {
  //   path: '/supervision',
  //   name: 'Supervision',
  //   redirect: '/supervision/list',
  //   component: emptyLayout,
  //   children: [
  //     {
  //       path: 'list',
  //       name: "SupervisionList",
  //       component: () => import(/* webpackChunkName: "supervision" */ "../views/supervision/index.vue")
  //     },
  //     {
  //       path: "wait-evaluated-detail",
  //       name: "WaitEvaluatedDetail",
  //       component: () => import(/* webpackChunkName: "supervision" */ "../views/supervision/waitEvaluated.vue")
  //     },
  //     {

  //       path: "already-evaluated-detail",
  //       name: "AlreadyEvaluatedDetail",
  //       component: () => import(/* webpackChunkName: "supervision" */ "../views/supervision/alreadyEvaluatedDetail.vue")
  //     },
  //     {
  //       path: "be-evaluated-detail",
  //       name: "BeEvaluatedDetail",
  //       component: () => import(/* webpackChunkName: "supervision" */ "../views/supervision/beEvaluatedDetail.vue")
  //     }
  //   ]
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory('/v111/'),
  // history: createWebHashHistory('/v110'),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path === "/" || to.path === "/login" || to.path === "/wxlogin" || to.path === "/ddMessage") {
    next();
  } else {
    const userInfo = localStorage.getItem("UserInfo");
    const orgId = localStorage.getItem("OrgCode");
    if (to.path == "/unifylogin" || to.path == "/logout") {
      next();
    } else {
      if (!userInfo && !orgId) {
        (wx as any).miniProgram.navigateTo({ url: "../scan/index" });
      }
      next();
    }
    // if (userInfo) {
    //   next();
    // } else {
    //   next({ path: '/login' });
    // }
  }
})

export default router
