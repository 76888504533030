import { createApp, VueElement } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import websocket from "vue-native-websocket-vue3";
import { Decrypt } from "@/scripts/utils";

import Vant from 'vant'
import 'vant/lib/index.css'

// 字体适配
import "amfe-flexible/index.js";

import "./assets/icons/iconfont.css";
import "./assets/styles/index.less";
import HeadTop from "./components/HeadTop.vue";
import VConsole from 'vconsole'; 
const vConsole = (window as any).Config.IsConsole ? new VConsole({ // 配置选项 
    defaultPlugins: ['system', 'network', 'element'], 
    // 默认开启的插件 
    onReady() { 
        console.log('vConsole is ready!'); 
    }, 
}) : null;
const app = createApp(App);
const ORGTOKEN = localStorage.getItem("OrgToken") ? Decrypt(localStorage.getItem("OrgToken")) : '';

if (ORGTOKEN) {

    app.use(websocket, `wss://${(window as any).Config.Host}/${ORGTOKEN}/V4ws`, {
        reconnection: true, // (Boolean)是否自动重连，默认false
        reconnectionAttempts: 5, // 重连次数
        reconnectionDelay: 3000, // 再次重连等待时常(1000)
    });
}
// app.config.isCustomElement = tag => tag.startsWith('wx-open-launch-weapp')
app.use(store).use(router).use(Vant).component("head-top", HeadTop).mount('#app');
