import { createStore } from 'vuex'

export default createStore({
  state: {
    SrvConfigInfo: null,
    Permissions: [],
    StudentSearch:null,
  },
  mutations: {
    updateState(state: any, { key, newState }) {
      state[key] = newState;
    }
  },
  actions: {
  },
  modules: {
  }
})
