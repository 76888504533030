<template>
  <div>
    <div class="head-top">
      <span v-if="showBack" @click="goBack" class="head-back">
        <van-icon name="arrow-left" />
      </span>
      <span class="header-title">{{ title }}</span>
      <slot></slot>
    </div>
    <div class="head-seat"></div>
  </div>
</template>

<script>
import { Icon } from "vant";
export default {
  components: {
    [Icon.name]: Icon,
  },
  props: {
    title: String,
    showBack: {
      type: Boolean,
      default: true,
    },
    isBackHome: {
      type: Boolean,
      default: false,
    },
    assignURL: {
      type: String,
      default: "",
    },
  },
  methods: {
    goBack() {
      if (this.isBackHome) {
        this.$router.push("/");
      } else {
        if (this.assignURL) {
          // 指定返回页
          this.$router.replace(this.assignURL);
        } else {
          this.$router.go(-1);
        }
      }
    },
  },
};
</script>

<style lang="less">
@import url("../assets/styles/base.less");

@height: 45px;
.head-top {
  width: 100%;
  height: @height;
  background: @primary-color;
  position: fixed;
  top: 0;
  z-index: 999;

  .head-back,
  .head-right-icon {
    width: 10vw;
    height: @height;
    text-align: center;
    line-height: @height;
    font-size: @font-title;
    color: #fff;
    position: absolute;

    .iconfont {
      font-size: 18px;
    }
  }

  .head-back {
    left: 0;
  }

  .header-title {
    width: 80vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
    color: #fff;
    font-size: @font-title;
    line-height: @height;
    position: absolute;
    top: 50%;
    left: 10vw;
    transform: translate(0, -50%);
  }
  .head-right-icon {
    right: 0;
  }
}

.head-seat {
  width: 100%;
  height: @height;
}
</style>
