
import apiCommon from "@/api/common";
import { Decrypt } from "@/scripts/utils";

export default {
  watch: {
    async $route(newVal: any, oldVal: any) {
      let self = this as any;
      if (!self.$store.state.SrvConfigInfo) {
        const ORGTOKEN = localStorage.getItem("OrgToken")
          ? Decrypt(localStorage.getItem("OrgToken"))
          : "";
        if (ORGTOKEN) {
          console.log("服务端配置信息不存在，重新获取一下。");
          const res = (await apiCommon.GetConfigInfo(ORGTOKEN)) as any;
          if (res.Result && res.Data) {
            // console.log(JSON.parse(res.Data));
            self.$store.commit("updateState", {
              key: "SrvConfigInfo",
              newState: JSON.parse(res.Data),
            });
            // if (this.SrvConfigInfo.Neutral) {
            //   this.PlatformName = this.SrvConfigInfo.AdminPlatformName;
            // }
          }
        }
      }
    },
  },
};
